import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  ClickAwayListener,
  MenuList,
  MenuItem,
  Button,
  Popper,
  Paper,
  Grow
} from '@mui/material';

import PuffLoader from 'react-spinners/PuffLoader';
import i18n from '../../../i18n';

import './index.scss';

/**
 * Render DropDownList for React-Table
 * @memberof module:ReactTable
 */
const DropDownList = (props) => {
  const {
    DATA_LIST_URL,
    i18TitleValue,
    DATA_LIST_ID,
    dropDownType,
    disabledKey = '',
    customStyles = {},
    titleValue = '',
    menuItems = [],
    setOpen,
    open,
    request,
    original,
    disabled,
    disablePortal = true,
    isLoading,
    popperProps = {},
    menuItemProps = {}
  } = props;

  const dispatch = useDispatch();
  const anchorRef = useRef(null);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = (event) => {
    if (!anchorRef?.current?.contains(event.target)) {
      setOpen(false);
    }
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  const clickHandler = (value) => {
    const data = {
      targets: [
        original._id
      ],
      values: {
        [dropDownType]: value
      }
    };
    dispatch(request({ url: DATA_LIST_URL, listID: DATA_LIST_ID, config: data }));
    setOpen(false);
  };

  return (
    <div className={`drop-down-list drop-down-list-${dropDownType}`}>
      <Button
        ref={anchorRef}
        aria-controls={open ? `drop-down-list-${dropDownType}-grow` : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={`drop-down-list-${dropDownType}-btn drop-down-list-${dropDownType}-btn__${titleValue.replaceAll(' ', '')}`}
        style={customStyles}
        disabled={disabled}
      >
        {i18TitleValue}
        {!isLoading && <i className="fa fa-caret-down" />}
        {isLoading && <PuffLoader color="#000" size={12} />}
      </Button>
      <Popper
        {...popperProps}
        open={open}
        sx={{ maxWidth: customStyles.width, overflowWrap: 'break-word' }}
        anchorEl={anchorRef.current}
        transition
        disablePortal={disablePortal}
        placement="bottom-start"
        modifiers={[{ name: 'flip', enabled: false }]}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id={`drop-down-list-${dropDownType}-grow`} onKeyDown={handleListKeyDown}>
                  {menuItems.map(({ key, value, onClick }) => {
                    const clickHandlerFromList = () => {
                      onClick();
                      setOpen(false);
                    };

                    return (
                      <MenuItem
                        key={key}
                        className={`drop-down-list-${dropDownType}-grow__${value.replaceAll(' ', '')}`}
                        {...menuItemProps}
                        disabled={key === disabledKey}
                        sx={{ whiteSpace: 'normal' }}
                        onClick={onClick ? clickHandlerFromList : clickHandler.bind(null, key)}
                      >
                        {i18n.exists(value) ? i18n.t(value) : value}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

DropDownList.propTypes = {
  original: PropTypes.instanceOf(Object),
  customStyles: PropTypes.instanceOf(Object),
  menuItems: PropTypes.instanceOf(Array),
  i18TitleValue: PropTypes.string.isRequired,
  dropDownType: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  DATA_LIST_ID: PropTypes.string,
  DATA_LIST_URL: PropTypes.string,
  disabledKey: PropTypes.string,
  titleValue: PropTypes.string,
  request: PropTypes.func,
  disabled: PropTypes.bool,
  disablePortal: PropTypes.bool,
  isLoading: PropTypes.bool,
  popperProps: PropTypes.instanceOf(Object),
  menuItemProps: PropTypes.instanceOf(Object)
};

export default DropDownList;
